<template>
  <div class="jobResult">
    <div class="title">job运行情况：
        <van-button size="mini" icon="replay" @click="getMsg">刷新</van-button>
    </div>
    <div class="msg">
      <van-row :gutter="3">
        <van-col :span="5">
          <p>状态</p>
          <div class="val">{{dataMsg.status}}</div>
        </van-col>
        <van-col :span="3">
          <p>耗时</p>
          <div class="val">{{dataMsg.duration}}</div>
        </van-col>
        <van-col :span="8">
          <p>开始时间</p>
          <div class="val">{{dataMsg.startTime}}</div>
        </van-col>
        <van-col :span="8">
          <p>结束时间</p>
          <div class="val">{{dataMsg.endTime}}</div>
        </van-col>
      </van-row>
    </div>
    <div class="content">
      <van-collapse v-model="activeNames">
        <van-collapse-item title="job参数：" name="1">
          <van-cell>
            <van-row :gutter="3">
              <van-col :span="8"> 名称 </van-col>
              <van-col :span="5"> 类型 </van-col>
              <van-col :span="11"> 值 </van-col>
            </van-row>
          </van-cell>
          <van-cell v-for="(key,index) in paramsList" :key="index">
            <van-row :gutter="3">
              <van-col :span="8"> {{key}} </van-col>
              <van-col :span="5"> {{dataMsg.jobParameters.parameters[key].type}} </van-col>
              <van-col :span="11"> {{dataMsg.jobParameters.parameters[key].value}} </van-col>
            </van-row>
          </van-cell>
        </van-collapse-item>
        <van-collapse-item title="步骤" name="2" class="step">
            <van-cell>
            <van-row :gutter="3" type="flex">
              <van-col :span="6"> 步骤名称 </van-col>
              <van-col :span="2"> 状态 </van-col>
              <van-col :span="6"> 开始时间 </van-col>
              <van-col :span="6"> 结束时间 </van-col>
              <van-col :span="2"> 耗时 </van-col>
            </van-row>
          </van-cell>
          <van-cell v-for="(item,index) in dataMsg.stepExecutions" :key="index">
            <van-row :gutter="3">
              <van-col :span="6"> {{item.stepName}} </van-col>
              <van-col :span="2"> {{item.status}} </van-col>
              <van-col :span="6"> {{item.startTime}} </van-col>
              <van-col :span="6"> {{item.endTime}} </van-col>
              <van-col :span="2"> {{item.duration}} </van-col>
            </van-row>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
import {jobDetail} from '@/api/campaign'
export default {
  data() {
    return {
      activeNames: ["1"],
      data1:[
          {name:'addJobTime',type:'long',val:'486564121247'},
          {name:'addJobTime',type:'long',val:'486564121247'},
          {name:'addJobTime',type:'long',val:'486564121247'},
          {name:'addJobTime',type:'long',val:'486564121247'},
      ],
      dataMsg:{},
      paramsList:[]
    };
  },
  methods:{
    getMsg(){
      let id = this.$route.query.id;
      jobDetail({id:id}).then(res=>{
        console.log(res);
        this.dataMsg = res.data;
        this.paramsList = res.data.jobParameters.empty?[]:Object.keys(res.data.jobParameters.parameters)
      })
    }
  },
  created(){
    this.getMsg()
  }
};
</script> 
<style lang="scss">
.jobResult {
  padding: 0 20px;
  .title {
    padding: 30px 0;
    font-size: 34px;
    font-weight: bolder;
    border-bottom: 1px solid #ebedf0;
  }
  .msg {
    font-size: 30px;
    p {
      line-height: 1.5;
      font-weight: bolder;
      padding: 10px 0;
    }
    .val {
      line-height: 1.5;
      color: #666;
    }
  }
  .content {
    margin-top: 30px;
    .van-collapse-item__wrapper{
        overflow-x: auto;
    }
    .step{
        .van-collapse-item__content{
            width: 200%;
        }
    }
    .van-cell,
    .van-collapse-item__content {
      padding: 10px 0;
      .van-cell{
          white-space: nowrap;
          overflow-x: auto;
      }
    }
  }
}
</style>